export const petitii = [
  {
    _id: 1,
    slug: 'petitie-oprirea-introducerii-sensului-unic',
    titlu:
      'Petiție privind oprirea introducerii sensului unic pe Bulevardul Ferdinand și strada Cuza Vodă',
    descriere:
      'Scopul acestei petiții este de a sustine menținerea configurații rutiere și păstrarea locurilor de parcare de pe strada Cuza Vodă sau găsirea unei soluții pentru locatarii și afacerilor din zonă.',
    initiator: 'Tineret Forța Dreptei Constanța',
    sheetLink:
      'https://script.google.com/macros/s/AKfycbzx6AUlZ2G6zFlkzYv8oxWQJ6dtleyq5KJlpxcPWAC00RkRW8OkpLW0u0U43AnGuYrg/exec',
  },
];

export const sondaje = [
  {
    _id: 1,
    slug: 'sondaj-privind-introducerea-sensului-unic',
    titlu:
      'Sondaj privind introducerea sensului unic pe Bulevardul Ferdinand și strada Cuza Vodă',
    descriere:
      'Scopul acestui sondaj este de a obține opinii cu privire la propunerea de introducere a sensului unic pe Bulevardul Ferdinand dinspre gară spre Mircea cel Bătrân și sens unic de întoarcere pe strada Cuza Vodă spre gară.',
    initiator: 'Tineret Forța Dreptei Constanța',
    sheetLink:
      'https://script.google.com/macros/s/AKfycbxNcI9XigUrgjFflbOTGQ28kp4CXrH7kkwVXW1ReR74HJXFG91SXKRamApX-2338qnE4A/exec',
    intrebari: [
      {
        _id: 11,
        intrebare:
          '1. Sunteți de acord cu introducerea sensului unic pe Bulevardul Ferdinand dinspre gară spre Mircea cel Bătrân?',
        raspunsuri: ['Da', 'Nu'],
      },
      {
        _id: 12,
        intrebare:
          '2. Sunteți de acord cu introducerea sensului unic de întoarcere pe strada Cuza Vodă spre gară?',
        raspunsuri: ['Da', 'Nu'],
      },
      {
        _id: 13,
        intrebare:
          '3. Credeți că introducerea sensului unic va îmbunătăți fluxul de trafic în zonă?',
        raspunsuri: ['Da', 'Nu'],
      },
      {
        _id: 14,
        intrebare:
          '4. Credeți că introducerea sensului unic va afecta negativ accesul la locuințe sau afaceri din zonă?',
        raspunsuri: ['Da', 'Nu'],
      },
      {
        _id: 15,
        intrebare:
          '5. Sunteți de acord cu eliminarea locurilor de parcare de pe strada Cuza Vodă ca urmare a introducerii sensului unic?',
        raspunsuri: ['Da', 'Nu'],
      },
      {
        _id: 16,
        intrebare:
          '6. Credeți că dispariția locurilor de parcare va avea un impact negativ asupra locuitorilor și afacerilor din zonă?',
        raspunsuri: ['Da', 'Nu'],
      },
      {
        _id: 17,
        intrebare:
          '7. Considerați că se impune crearea de noi locuri de parcare în zonă pentru a compensa cele eliminate?',
        raspunsuri: ['Da', 'Nu'],
      },
    ],
  },
];
