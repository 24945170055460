import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const Cards = ({ data, buttonText, link }) => {
  return (
    <Card maxW={'sm'} p={'1rem'}>
      <CardBody>
        <Stack>
          <Heading size={'md'} as={'h2'}>
            {data.titlu}
          </Heading>
          <Text>{data.descriere}</Text>
          <Text fontWeight={'bold'} fontSize={'.75rem'}>
            Inițiator: {data.initiator}
          </Text>
        </Stack>
      </CardBody>
      <Divider />
      <CardFooter>
        <Link to={link}>
          <Box
            bg={'brand'}
            color={'textLight'}
            px={'1.5rem'}
            py={'1rem'}
            borderRadius={'1rem'}
            fontSize={'1.25rem'}
            fontWeight={'bold'}
          >
            {buttonText}
          </Box>
        </Link>
      </CardFooter>
    </Card>
  );
};

export default Cards;
