import {
  Heading,
  HStack,
  IconButton,
  Image,
  Link as LinkC,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <Stack
      w={'full'}
      minH={'200px'}
      justifyContent={'center'}
      alignItems={'center'}
      bg={'brand'}
      color={'textLight'}
      gap={['2rem', null, '3rem']}
    >
      <Stack
        w={'full'}
        maxW={'90rem'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        py={'2rem'}
      >
        <HStack w={'full'} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Stack
            w={{ base: 'full', md: '250px' }}
            alignItems={'center'}
            gap={'1.5rem'}
          >
            <Image width={'250px'} src={'/images/Logo.png'} />
            <HStack
              w={'250px'}
              justifyContent={'space-between'}
              color={'textLight'}
            >
              <LinkC
                target="_blank"
                aria-label="Facebook"
                rel="noreferrer"
                href={'https://facebook.com'}
              >
                <IconButton
                  color={'textLight'}
                  variant={'ghost'}
                  fontSize={'2rem'}
                  _hover={{ bg: 'orange', color: 'textLight' }}
                  icon={<FaFacebook />}
                />
              </LinkC>
              <LinkC
                target="_blank"
                aria-label="Twitter"
                rel="noreferrer"
                href={'https://twitter.com'}
              >
                <IconButton
                  color={'textLight'}
                  variant={'ghost'}
                  fontSize={'2rem'}
                  _hover={{ bg: 'orange', color: 'textLight' }}
                  icon={<FaTwitter />}
                />
              </LinkC>
              <LinkC
                target="_blank"
                aria-label="Instagram"
                rel="noreferrer"
                href={'https://instagram.com'}
              >
                <IconButton
                  color={'textLight'}
                  variant={'ghost'}
                  fontSize={'2rem'}
                  _hover={{ bg: 'orange', color: 'textLight' }}
                  icon={<FaInstagram />}
                />
              </LinkC>
              <LinkC
                target="_blank"
                aria-label="Tiktok"
                rel="noreferrer"
                href={'https://tiktok.com'}
              >
                <IconButton
                  color={'textLight'}
                  variant={'ghost'}
                  fontSize={'2rem'}
                  _hover={{ bg: 'orange', color: 'textLight' }}
                  icon={<FaTiktok />}
                />
              </LinkC>
              <LinkC
                target="_blank"
                aria-label="Youtube"
                rel="noreferrer"
                href={'https://youtube.com'}
              >
                <IconButton
                  color={'textLight'}
                  variant={'ghost'}
                  fontSize={'2rem'}
                  _hover={{ bg: 'orange', color: 'textLight' }}
                  icon={<FaYoutube />}
                />
              </LinkC>
            </HStack>
          </Stack>
          <Stack
            gap={'1.5rem'}
            w={{ base: 'full', md: '250px' }}
            alignItems={'center'}
            fontSize={'1.25rem'}
          >
            <Heading as={'h3'}>Politici</Heading>
            <LinkC
              target="_blank"
              aria-label="ANPC - SOL"
              rel="noreferrer"
              href={'https://autopost.ro/termeni-conditii'}
            >
              Termeni și condiții
            </LinkC>
            <LinkC
              target="_blank"
              aria-label="ANPC - SOL"
              rel="noreferrer"
              href={'https://autopost.ro/gdpr'}
            >
              Politica GDPR
            </LinkC>
          </Stack>
          <Stack w={{ base: 'full', md: '250px' }} alignItems={'center'}>
            {' '}
            <LinkC
              target="_blank"
              aria-label="ANPC - SOL"
              rel="noreferrer"
              href={'https://ec.europa.eu/consumers/odr'}
            >
              <Image w={'200px'} src={'/images/SOL.png'} />
            </LinkC>
            <LinkC
              target="_blank"
              aria-label="ANPC - SAL"
              rel="noreferrer"
              href={'https://ec.europa.eu/consumers/odr'}
            >
              <Image w={'200px'} src={'/images/SAL.png'} />
            </LinkC>
          </Stack>
        </HStack>
      </Stack>
      <HStack
        bgColor={'titleDark'}
        w={'full'}
        alignItems={'center'}
        justifyContent={'center'}
        px={{ base: '1rem', lg: 0 }}
        minH={'50px'}
      >
        <Text>
          Digidev Innotech SRL{' '}
          <strong>&#9400; {new Date().getFullYear()}</strong>. All rights
          reserved.
        </Text>
      </HStack>
    </Stack>
  );
};

export default Footer;
