import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HStack, Heading, Stack } from '@chakra-ui/react';
import { petitii } from '../constants/data';
import Cards from '../components/Cards';

const Petitii = () => {
  return (
    <Stack
      h={'100vh'}
      w={'full'}
      maxW={'100rem'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      gap={{ base: '1rem', md: '2rem' }}
      mt={'3rem'}
    >
      <Helmet>
        <title>Vox populis - Petitii</title>
      </Helmet>
      <HStack w={'full'} alignItems={'center'} justifyContent={'center'}>
        <Heading as={'h1'} w={'fit-content'}>
          Petițiile VOX
        </Heading>
      </HStack>
      <Stack w={'full'} flexWrap={'wrap'}>
        {petitii.map((item) => (
          <Cards
            key={item._id}
            id={item._id}
            data={item}
            buttonText="Sustine initiativa"
            link={`/petitii/${item.slug}`}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default Petitii;
