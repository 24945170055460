import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Footer, Sidebar } from './components';
import { Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { Petitii, Sondaje, Sondaj, Petitie, Home } from './pages';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Router>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Stack
        w={'full'}
        minH={{ base: 'calc(100vh - 200px)', lg: 'calc(100vh - 250px)' }}
        alignItems={'center'}
        justifyContent={'center'}
        className="appStack"
      >
        <Routes>
          <Route path={'/'} element={<Home />} />
          <Route path={'/petitii'} element={<Petitii />} />
          <Route path={'/sondaje'} element={<Sondaje />} />
          <Route path={'/petitii/:slug'} element={<Petitie />} />
          <Route path={'/sondaje/:slug'} element={<Sondaj />} />
        </Routes>
      </Stack>

      <Footer />
    </Router>
  );
}

export default App;
