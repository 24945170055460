import React, { useEffect, useRef, useState } from 'react';

import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';

import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { petitii } from '../constants/data';
const Petitie = () => {
  const formRef = useRef(null);
  const params = useParams();
  const { slug } = params;
  console.log(params);
  const [showToast, setShowToast] = useState(false);
  const [sheetUrl, setSheetUrl] = useState(
    'https://script.google.com/macros/s/AKfycbzx6AUlZ2G6zFlkzYv8oxWQJ6dtleyq5KJlpxcPWAC00RkRW8OkpLW0u0U43AnGuYrg/exec'
  );
  const [petitie, setPetitie] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const result = await petitii.find((item) => item.slug === slug);
      setPetitie(result);
      console.log(result);
      setSheetUrl(result.sheetLink);
      console.log(result.sheetLink);
    };
    fetchData();
  }, [slug]);

  const submitForm = (e) => {
    const formData = new FormData(formRef.current);
    e.preventDefault();
    fetch(sheetUrl, {
      method: 'POST',
      body: formData,
    })
      .then(formRef.current.reset(), setShowToast(true))
      .catch((err) => toast.error(err));
  };
  return (
    <Stack
      w={'full'}
      bg={'brand'}
      minH={'100vh'}
      alignItems={'center'}
      justifyContent={'flex-start'}
    >
      <Helmet>
        <title>{petitie.titlu}</title>
      </Helmet>
      <Stack
        w={'full'}
        maxW={'90rem'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'3rem'}
        py={{ base: '3rem', lg: 0 }}
        className="formStack"
        mt={'3rem'}
      >
        <Heading
          as={'h1'}
          w={'fit-content'}
          fontSize={{ base: '1.4rem', lg: '1.8rem' }}
          textAlign={'center'}
          color={'titleLight'}
        >
          Completează formularul și susține inițiativa
        </Heading>
        <Stack w={'full'} alignItems={'center'} justifyContent={'center'}>
          <form ref={formRef} className="form" onSubmit={submitForm}>
            <Stack
              w={{ base: '350px', lg: '700px' }}
              gap={'1rem'}
              color={'textLight'}
            >
              <FormControl isRequired>
                <FormLabel htmlFor="name">Nume:</FormLabel>
                <Input type="text" name="Nume" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="email">Prenume:</FormLabel>
                <Input type="text" name="Prenume" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="email">Județ:</FormLabel>
                <Input type="text" name="Judet" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="email">Localitate:</FormLabel>
                <Input type="text" name="Localitate" />
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="email">Telefon:</FormLabel>
                <Input type="number" name="Telefon" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="email">Email:</FormLabel>
                <Input type="email" name="Email" />
              </FormControl>
              <FormControl isRequired>
                <CheckboxGroup colorScheme={'green'}>
                  <Checkbox value={'gdpr'}>
                    Sunt de acord cu{' '}
                    <Link to="/gdpr" className="policyLink">
                      Politica GDPR
                    </Link>
                  </Checkbox>
                </CheckboxGroup>
              </FormControl>
              {showToast && (
                <FormControl h={'50px'}>
                  <Alert status="success" color={'brand'}>
                    <AlertIcon /> Mulțumim pentru susținere!
                  </Alert>
                </FormControl>
              )}
              <FormControl>
                <Button bg={'#e8e8e8'} color={'textDark'} type="submit">
                  Susține-ne
                </Button>
              </FormControl>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Petitie;
