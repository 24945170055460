import { Stack, Text } from '@chakra-ui/react';
import React from 'react';

const Home = () => {
  return (
    <Stack
      w={'full'}
      maxW={'100rem'}
      minH={'100vh'}
      justifyContent={'center'}
      alignItems={'center'}
      px={'1rem'}
    >
      <Text
        w={{ base: 'full', md: '50rem' }}
        lineHeight={'3rem'}
        fontSize={'1.5rem'}
      >
        Suntem platforma online dedicată exprimării opiniei și vocii cetățenilor
        - fie că este vorba despre sondaje, petiții sau știri importante. La VOX
        Populi credem că fiecare voce contează, iar democrația se hrănește din
        diversitatea de păreri și idei. Aici, <strong>TU</strong>, cetățeanul,
        ai puterea de a influența direcția societății și de a contribui la
        schimbările pe care le dorești comunitate.
      </Text>
    </Stack>
  );
};

export default Home;
