import { HStack, Heading, Stack } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Cards from '../components/Cards';
import { sondaje } from '../constants/data';

const Sondaje = () => {
  return (
    <Stack
      w={'full'}
      h={'100vh'}
      maxW={'100rem'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      gap={{ base: '1rem', md: '2rem' }}
      mt={'3rem'}
    >
      <Helmet>
        <title>Vox populis - Sondaje</title>
      </Helmet>
      <HStack w={'full'} alignItems={'center'} justifyContent={'center'}>
        <Heading as={'h1'}>Sondajele VOX</Heading>
      </HStack>
      <Stack w={'full'} flexWrap={'wrap'}>
        {sondaje.map((item) => (
          <Cards
            key={item._id}
            data={item}
            buttonText="Voteaza și tu!"
            link={`/sondaje/${item.slug}`}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default Sondaje;
