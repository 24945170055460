import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { sondaje } from '../constants/data';
import { toast } from 'react-toastify';

const Sondaj = () => {
  const formRef = useRef(null);
  const params = useParams();
  const { slug } = params;

  const navigate = useNavigate();

  const [choices, setChoice] = useState([]);
  const [results, setResults] = useState({});

  const [showToast, setShowToast] = useState(false);
  const [sheetUrl, setSheetUrl] = useState('');
  const [sondaj, setSondaj] = useState('');

  useEffect(() => {
    localStorage.setItem('vote-result', JSON.stringify(results));
    const fetchData = async () => {
      const result = await sondaje.find((item) => item.slug === slug);
      setSondaj(result);
      setSheetUrl(result.sheetLink);
    };
    fetchData();
  }, [results, slug]);

  const submitForm = (e) => {
    const formData = new FormData(formRef.current);

    fetch(sheetUrl, {
      method: 'POST',
      body: formData,
    })
      .then(formRef.current.reset(), setShowToast(true))
      .catch((err) => toast.error(err));
    if (!localStorage.getItem('vote-result')) {
      localStorage.setItem('vote-result', JSON.stringify({}));
    }
    setResults({ ...results, [choices]: (results[choices] ?? 0) + 1 });
    navigate('/sondaje');
  };
  return (
    <Stack
      w={'full'}
      bg={'brand'}
      minH={'100vh'}
      mt={{ base: '0', md: '-150px' }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Helmet>
        <title>
          Sondaj privind introducerea sensului unic pe Bulevardul Ferdinand și
          strada Cuza Vodă
        </title>
      </Helmet>
      <Stack
        w={'full'}
        maxW={'90rem'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'3rem'}
        py={{ base: '3rem', lg: 0 }}
        className="formStack"
      >
        <Heading
          as={'h1'}
          w={'fit-content'}
          fontSize={{ base: '1.4rem', lg: '1.8rem' }}
          textAlign={'center'}
          color={'titleLight'}
        >
          Sondaj privind introducerea sensului unic pe Bulevardul Ferdinand și
          strada Cuza Vodă
        </Heading>
        <Stack
          minH={'100vh'}
          w={'full'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <form ref={formRef} className="form" onSubmit={submitForm}>
            <Stack
              w={{ base: '350px', lg: '700px' }}
              gap={'1rem'}
              color={'textLight'}
            >
              <FormControl isRequired>
                <FormLabel htmlFor="Q1">
                  1. Sunteți de acord cu introducerea sensului unic pe
                  Bulevardul Ferdinand dinspre gară spre Mircea cel Bătrân?
                </FormLabel>
                <RadioGroup>
                  <HStack gap={'1rem'}>
                    <Radio
                      value="Da"
                      name="Q1"
                      checked={'Da' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Da
                    </Radio>
                    <Radio
                      value="Nu"
                      name="Q1"
                      checked={'Nu' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Nu
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="Q2">
                  2. Sunteți de acord cu introducerea sensului unic de
                  întoarcere pe strada Cuza Vodă spre gară?
                </FormLabel>
                <RadioGroup>
                  <HStack gap={'1rem'}>
                    <Radio
                      value="Da"
                      name="Q2"
                      checked={'Da' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Da
                    </Radio>
                    <Radio
                      value="Nu"
                      name="Q2"
                      checked={'Nu' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Nu
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="Q3">
                  3. Credeți că introducerea sensului unic va îmbunătăți fluxul
                  de trafic în zonă?
                </FormLabel>
                <RadioGroup>
                  <HStack gap={'1rem'}>
                    <Radio
                      value="Da"
                      name="Q3"
                      checked={'Da' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Da
                    </Radio>
                    <Radio
                      value="Nu"
                      name="Q3"
                      checked={'Nu' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Nu
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="Q4">
                  4. Credeți că introducerea sensului unic va afecta negativ
                  accesul la locuințe sau afaceri din zonă?
                </FormLabel>
                <RadioGroup>
                  <HStack gap={'1rem'}>
                    <Radio
                      value="Da"
                      name="Q4"
                      checked={'Da' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Da
                    </Radio>
                    <Radio
                      value="Nu"
                      name="Q4"
                      checked={'Nu' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Nu
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel htmlFor="Q5">
                  5. Sunteți de acord cu eliminarea locurilor de parcare de pe
                  strada Cuza Vodă ca urmare a introducerii sensului unic?
                </FormLabel>
                <RadioGroup>
                  <HStack gap={'1rem'}>
                    <Radio
                      value="Da"
                      name="Q5"
                      checked={'Da' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Da
                    </Radio>
                    <Radio
                      value="Nu"
                      name="Q5"
                      checked={'Nu' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Nu
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="Q6">
                  6. Credeți că dispariția locurilor de parcare va avea un
                  impact negativ asupra locuitorilor și afacerilor din zonă?
                </FormLabel>
                <RadioGroup>
                  <HStack gap={'1rem'}>
                    <Radio
                      value="Da"
                      name="Q6"
                      checked={'Da' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Da
                    </Radio>
                    <Radio
                      value="Nu"
                      name="Q6"
                      checked={'Nu' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Nu
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor="Q7">
                  7. Considerați că se impune crearea de noi locuri de parcare
                  în zonă pentru a compensa cele eliminate?
                </FormLabel>
                <RadioGroup>
                  <HStack gap={'1rem'}>
                    <Radio
                      value="Da"
                      name="Q7"
                      checked={'Da' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Da
                    </Radio>
                    <Radio
                      value="Nu"
                      name="Q7"
                      checked={'Nu' === choices}
                      onChange={(e) => setChoice([...choices, e.target.value])}
                    >
                      Nu
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>

              <FormControl isRequired>
                <CheckboxGroup colorScheme={'green'}>
                  <Checkbox value={'gdpr'}>
                    Sunt de acord cu{' '}
                    <Link to="/gdpr" className="policyLink">
                      Politica GDPR
                    </Link>
                  </Checkbox>
                </CheckboxGroup>
              </FormControl>
              {showToast && (
                <FormControl h={'50px'}>
                  <Alert status="success" color={'brand'}>
                    <AlertIcon /> Mulțumim pentru susținere!
                  </Alert>
                </FormControl>
              )}
              <FormControl>
                <Button bg={'#e8e8e8'} color={'textDark'} type="submit">
                  Susține-ne
                </Button>
              </FormControl>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Sondaj;
