import {
  Box,
  HStack,
  IconButton,
  Image,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

const Navbar = ({ toggle }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <HStack
      position={'relative'}
      justifyContent={'center'}
      alignItems={'center'}
      w={'full'}
      zIndex={10}
      h={{ base: '100px', lg: '150px' }}
      bg={{ base: 'brand', lg: 'brand' }}
    >
      <HStack
        justifyContent={'center'}
        alignItems={'center'}
        w={'full'}
        maxW={'90rem'}
        px={{ base: '2rem', lg: 0 }}
      >
        <HStack
          w={'full'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box>
            <Link to="/">
              <Image
                w={{ base: '100px', lg: '200px' }}
                src={'/images/Logo.png'}
                alt={'Vox Populis'}
              />
            </Link>
          </Box>
          {isDesktop ? (
            <HStack
              gap={'2rem'}
              color={'textLight'}
              fontWeight={'bold'}
              letterSpacing={'1.3px'}
              fontSize={'1.5rem'}
            >
              <Box
                transition={'all linear .3s'}
                _hover={{
                  textDecoration: 'underline',
                  textDecorationThickness: '2px',
                }}
              >
                <Link to={'/'}>Acasa</Link>
              </Box>
              <Box
                transition={'all linear .3s'}
                _hover={{
                  textDecoration: 'underline',
                  textDecorationThickness: '2px',
                }}
              >
                <Link to={'/sondaje'}>Sondaje</Link>
              </Box>
              <Box
                transition={'all linear .3s'}
                _hover={{
                  textDecoration: 'underline',
                  textDecorationThickness: '2px',
                }}
              >
                <Link to={'/petitii'}>Petiții</Link>
              </Box>
            </HStack>
          ) : (
            <IconButton
              variant="ghost"
              color={'textLight'}
              icon={<FiMenu fontSize="1.5rem" />}
              aria-label="Open Menu"
              _hover={'none'}
              _active={'none'}
              _focus={'none'}
              onClick={toggle}
            />
          )}
        </HStack>
      </HStack>
    </HStack>
  );
};
export default Navbar;
